#socials {
  display: flex;
  justify-content: space-evenly;
  padding-top: 2rem;

  li {
    padding-left: 0;
  }

  a {
    color: white;
    font-family: 'Montserrat';
    font-weight: 600;
  }
}

#technologies {
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  padding-left: 1rem;
  max-height: 90px;

  li {
    padding-left: 0;
  }
}

#experience {
  li {
    display: flex;
    justify-content: space-between;
  }
}

h1 {
  margin-top: 0;
  font-family: 'Montserrat';
  font-size: 5rem;
  margin-bottom: 0;
}

h3 {
  margin-top: 0;
  padding-bottom: 2rem;
}

.experience {
    max-width: 453px;
}
