@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;1,400&display=swap');

.App {
  background-color: black;
  height: 100vh;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: flex-end;
}

.wrapper {
  padding-top: 23.6vh;
  overflow: auto;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.container {
  color: #f2f2f2;
  text-align: left;
  width: 61vw;
  min-width: 453px;
  overflow: hidden;
  margin-left: 1rem;

  ul {
    list-style: none;
    padding: 0;
    font-family: 'Source Sans Pro';

    li {
      padding-left: 1rem;
    }
  }
}

.nav {
  padding-top: 26vh;
  text-align: right;
  color: white;
  min-width: 7rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
    font-weight: 500;

    li {
      padding: 0.3rem;
      cursor: pointer;
    }

    li:hover {
      text-decoration: underline;
      text-decoration-thickness: 0.125rem;
    }
  }
}

@media screen and (max-width: 600px) {
  #technologies {
    max-height: 60px;
    padding-left: 0rem;
  }

  #socials {
    padding-top: 1rem;
  }

  .container {
    width: auto;
    margin-left: 0;
    min-width: auto;

    h4 {
      margin-bottom: 0.5rem;
    }

    ul {
      li {
        padding-left: 0rem;
      }
    }
  }

  .App {
    justify-content: center;
  }

  .wrapper {
    padding-top: 3rem;
  }

  .experience {
    padding: 0.5rem;
  }

  .nav {
    position: fixed;
    padding-top: 0;
    background-color: black;
    width: 100%;

    ul {
      li {
        float: left;
        padding: 1rem;
      }
    }
  }

  .photography-wrapper {
    margin-top: 3rem;
  }
}
